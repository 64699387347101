import Box from "@mui/material/Box";
import VendorView from "./VendorView.js";

function VendorViewScreen() {

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <VendorView isAdmin={false} />
    </Box>
  );
}

export default VendorViewScreen;

import Axios from "axios";
import { useState, React, useEffect } from "react";
import { useFormik } from "formik";
import { MenuItem, Button, Box, Select, InputLabel, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PopupMessage from "../PopupMessage/PopupMessage.js";
import "./StockEntry.css";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
// Search functionality
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import fetchSearchResults from "../utils/fetchSearchResults.js";
import dayjs from "dayjs";
import AlertDialog from "../UI/AlertDialog";
import LoaderOverlay from "../Loader/LoaderOverlay.js";
import { useDebounce } from "../utils/Hooks.js";
import { ForestRounded } from "@mui/icons-material";

const isInventoryManager = localStorage.getItem("inventorymanagerid") !== null;
const imID = localStorage.getItem("inventorymanagerid");

const initialValues = {
  productid: "",
  name: "",
  phone: "",
  batchno: "",
  unitcost: "",
  totalquantity: "",
  doe: "",
  dom: "",
};

// Add styled components for the search input and results container
const SearchIconWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StockEntry = () => {
  const hospitalid = localStorage.getItem("hospitalid");
  const [gst, setGST] = useState(""); // Add a new state variable for GST rate
  const [grandtotal, setGrandTotal] = useState(0); // Add a new state variable for
  const [category, setCategory] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [upc, setUpc] = useState(null);
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [doe, setDoe] = useState(null);
  const [dom, setDom] = useState(null);
  const [open, setOpen] = useState(false);
  const [isStockRegistered, setIsStockRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [stockEntries, setStockEntries] = useState([]);
  const [stockId, setStockId] = useState([]);
  const [stockProductArray, setStockProductArray] = useState([]);
  const [existQuantity, setExistQuantity] = useState([]);
  const [name, setName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [quantityError, setQuantityError] = useState("");
  let [loading, setLoading] = useState(false);
  // State variables for search functionality
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [vendorDetails, setVendorDetails] = useState([]);
  const [vendorSearchResults, setVendorSearchResults] = useState([]);
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");
  const [vendorid, setVendorId] = useState("");

  // Handle search input changes
  const handleSearchChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim().length >= 3) {
      try {
        const results = await fetchSearchResults(term);
        setSearchResults(results);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };
  const fetchVendorDetails = async () => {
    try {
      const hospitalid = localStorage.getItem("hospitalid");
      const url = `${process.env.REACT_APP_BASE_URL}vendors/${hospitalid}`;
      const { data } = await axios.get(url);
      setVendorDetails(data.vendors);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const [states, setStates] = useState([])
  async function getStates() {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}v2/states/`;
      const { data } = await axios.get(url);
      setStates(data)
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    // fetchVendorDetails();
    getStates()
    // TODO : add id of the state instead of state name string 
  }, []);

  // using debounce on text to eleiminate unnecessary api calls 
  const debouncedVendorSearchTerm = useDebounce(vendorSearchTerm, 300)
  useEffect(() => {
    async function getVendor() {
      try {
        if (debouncedVendorSearchTerm === "") return
        const url = `${process.env.REACT_APP_BASE_URL}v2/vendor`;
        const { data } = await axios.get(url, {
          params: { name: debouncedVendorSearchTerm }
        });
        setVendorSearchResults(data);
      } catch (error) {
        console.error("Error fetching vendor search results:", error);
        setVendorSearchResults([]);
      }
    }
    getVendor()
  }, [debouncedVendorSearchTerm])

  const handleVendorSearchChange = async (event) => {
    formik.setFieldValue("vendorid", "")
    const term = event.target.value;
    setVendorSearchTerm(term);
  };

  const [selectedVendor, setSelectedVednor] = useState(null)
  const handleVendorSelect = (vendor) => {
    setSelectedVednor(vendor)
    formik.setFieldValue("name", vendor.name);
    formik.setFieldValue("phone", vendor.phone);
    formik.setFieldValue("address", vendor.address || "");
    formik.setFieldValue("district", vendor.district || "");
    formik.setFieldValue("pin", vendor.pin || "");
    formik.setFieldValue("state", vendor.state || "");
    formik.setFieldValue("gstno", vendor.gstno || "");
    formik.setFieldValue("vendorid", vendor._id); // Set the vendorid
    setVendorSearchTerm("");
    setVendorSearchResults([]);
  };


  const getInputWidth = (inputId) => {
    const inputElement = document.getElementById(inputId);
    return inputElement ? inputElement.getBoundingClientRect().width : "100%";
  };

  // Handle product selection from search results
  const handleProductSelect = async (product) => {
    try {
      setSelectedProducts(product);
  
      // Fetch product type name
      let productTypeName = "-";
      if (product.producttype && product.producttype !== "-") {
        const productTypeResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}product-types`
        );
        const productType = productTypeResponse.data.productTypes.find(
          (type) => type._id === product.producttype
        );
        productTypeName = productType ? productType.name : "-";
      }
  
      // Fetch category name
      let categoryName = "-";
      if (product.category && product.category !== "-") {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}categories/${product.producttype}`
        );
        const category = categoryResponse.data.categories.find(
          (cat) => cat._id === product.category
        );
        categoryName = category ? category.name : "-";
      }
  
      // Fetch subcategory name
      let subcategoryName = "-";
      if (product.subcategory && product.subcategory !== "-") {
        const subcategoryResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}subcategories/${product.category}`
        );
        const subcategory = subcategoryResponse.data.subcategories.find(
          (subcat) => subcat._id === product.subcategory
        );
        subcategoryName = subcategory ? subcategory.name : "-";
      }
  
      // Set the fetched names in the state
      setCategory(`${categoryName} / ${subcategoryName}`);
      setType(productTypeName); // Set the product type name
      setUpc(product.upccode);
      setManufacturer(product.manufacturer);
      setId(product._id);
      setName(product.name);
      setSearchTerm("");
      setSearchResults([]);
  
      // Handle product image
      const imageData = product.productImage;
      if (imageData && imageData.data) {
        const base64String = bufferToBase64(imageData.data);
        setProductImage(`data:image/jpeg;base64,${base64String}`);
      } else {
        setProductImage(null); // Set to null if no data found
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };
  const fieldLabels = {
    name: "Name",
    phone: "Phone Number",
    batchno: "Batch Number",
    unitcost: "Unit Cost",
    totalquantity: "Total Quantity",
    gst: "GST Slab %",
    dom: "Date of Manufacturing",
    doe: "Date of PM",
    productname: "Select Product Name",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const StockSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Enter Valid Phone Number")
      .max(10, "Enter Valid Phone Number"),
    batchno: Yup.string().required("Batch Number is required"),
    unitcost: Yup.number()
      .typeError("Unit cost must be a number")
      .required("Unit cost is required"),
    totalquantity: Yup.number()
      .typeError("Total quantity must be a number")
      .required("Total quantity is required"),
    gst: Yup.string().required("Select Your GST Slab"),
    dom: Yup.string().required("Date of Manufacture is required"),
    doe: Yup.string().required("Date of PM is required"),
    productname: Yup.string().required("Product Name is required"),
    // TODO : add validation for gst number
  });

  const bufferToBase64 = (buf) => {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buf));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  // Function to highlight search term in search results
  const highlightSearchTerm = (text) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <b key={index} style={{ color: "black" }}>
              {part}
            </b>
          ) : (
            <span key={index}>{part}</span>
          ),
        )}
      </span>
    );
  };

  useEffect(() => {
    if (isStockRegistered) {
      const timer = setTimeout(() => {
        //window.location.reload(); // Reload the page after the desired delay
      }, 2000); // Adjust the delay as needed (in milliseconds)

      return () => clearTimeout(timer);
    }
  }, [isStockRegistered]);

  const getstock = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}stocks`;
      const { data } = await axios.get(url);
      const stockarray = new Array(data.document.length);
      const stockproductarray = new Array(data.document.length);
      const existquantity = new Array(data.document.length);

      for (let i = 0; i < data.document.length; i++) {
        stockarray[i] = data.document[i]._id;
        stockproductarray[i] = data.document[i].productid;
        existquantity[i] = data.document[i].totalquantity;
      }
      setStockId(stockarray);
      setStockProductArray(stockproductarray);
      setExistQuantity(existquantity);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getstock(); // Call getstock() when component mounts or dependencies change
  }, []);

  function checkStockExists(batchno, productid, hospitalid) {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}api/check-stock?batchno=${batchno}&productid=${productid}&hospitalid=${hospitalid}`,
    )
      .then((response) => response.json())
      .then((data) => {
        return data.exists; // Return true if stock exists, false otherwise
      })
      .catch((error) => {
        console.error("Error checking stock:", error);
        return false; // Handle error by returning false
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      batchno: "",
      unitcost: "",
      totalquantity: "",
      gst: "",
      dom: "",
      doe: "",
      productname: "",
      backDate: "",
      vendorid: "",
      gstno: "",
      state: "",
      address: "",
      district: "",
      pin: "",
    },
    validationSchema: StockSchema,
    onSubmit: (values, action) => {
      const handleStockSubmission = async () => {
        try {
          const hospitalid = localStorage.getItem("hospitalid");

          console.log(
            "Query Parameters:" + values.batchno + " " + id + " " + hospitalid
          );

          const stockExists = await checkStockExists(values.batchno, id, hospitalid);

          if (stockExists) {
            // Show dialog alert saying product with same batchno exists
            setShowAlertDialog(true);
            setAlertText("Product with the same batch number already exists.");
            return;
          }

          // Ensure vendor exists before proceeding
          let vendor = values.vendorid;
          if (!vendor) {
            const vendorResponse = await Axios.post(
              `${process.env.REACT_APP_BASE_URL}postvendor`,
              {
                hospitalid,
                name: values.name,
                phone: values.phone,
                address: values.address,
                district: values.district,
                pin: values.pin,
                state: values.state,
                gstno: values.gstno,
                status: "active",
              }
            );
            vendor = vendorResponse.data._id
            handleVendorSelect(vendorResponse.data)
          }

          if (!selectedVendor.hospitalid.includes(hospitalid)) {
            const updatedVendor = { ...selectedVendor }
            updatedVendor.hospitalid.push(hospitalid)

            const url = process.env.REACT_APP_BASE_URL + "v2/vendor/" + vendor
            const { data } = await axios.put(url, updatedVendor)
            setSelectedVednor(data)
          }

          let exist = false;
          let currStockId = null;
          let currentQuantity = null;

          for (let i = 0; i < stockProductArray.length; i++) {
            if (stockProductArray[i] === id) {
              exist = true;
              currStockId = stockId[i];
              currentQuantity = existQuantity[i];
              break;
            }
          }

          const stockEntry = {
            hospitalid,
            productid: id,
            name: values.name,
            phone: values.phone,
            address: values.address,
            district: values.district,
            pin: values.pin,
            state: values.state,
            gstno: values.gstno,
            batchno: values.batchno,
            unitcost: values.unitcost,
            totalquantity: values.totalquantity,
            gst: values.gst,
            grandtotal: grandtotal,
            buffervalue: +values.totalquantity * 0.15,
            doe: values.doe,
            dom: values.dom,
            upccode: upc,
            productname: values.productname,
            manufacturer: manufacturer,
            exist,
            currStockId,
            currentQuantity,
            backDate: values.backDate
              ? values.backDate
              : new Date().toLocaleDateString("en-IN").replaceAll("/", "-"),
            vendorid: vendor,
          };

          console.log("Stock Entry:", stockEntry);
          setStockEntries([...stockEntries, stockEntry]);

          // Reset the form fields except for Vendor Details
          action.resetForm({
            values: {
              name: values.name,
              phone: values.phone,
              address: values.address,
              district: values.district,
              pin: values.pin,
              state: values.state,
              gstno: values.gstno,
              vendorid: vendor,
              batchno: "",
              unitcost: "",
              totalquantity: "",
              gst: "",
              dom: "",
              doe: "",
              productname: "",
              backDate: "",
            },
          });

          setSelectedProducts(null);
          setUpc("");
          setManufacturer("");
          setType("");
          setCategory("");
          setDoe(null);
          setDom(null);
          setProductImage(null);
        } catch (error) {
          console.error("Error handling stock submission:", error);
          setShowAlertDialog(true);
          setAlertText("Error processing stock entry.");
        }
      };

      // Call the async function
      handleStockSubmission();
    },
  });


  const removeStockEntry = (index) => {
    const updatedEntries = [...stockEntries];
    updatedEntries.splice(index, 1);
    setStockEntries(updatedEntries);
  };

  const handleSubmitAllStockEntries = async () => {
    try {
      for (const stockEntry of stockEntries) {
        const {
          exist,
          currStockId,
          currentQuantity,
          hospitalid,
          productid,
          name,
          phone,
          address,
          district,
          pin,
          state,
          gstno,
          batchno,
          unitcost,
          totalquantity,
          gst,
          grandtotal,
          doe,
          dom,
          upccode,
          productname,
          manufacturer,
          imID,
          backDate,
          vendorid,
        } = stockEntry;

        // Print values to console
        console.log("Stock Entry Values:", {
          exist,
          currStockId,
          currentQuantity,
          hospitalid,
          productid,
          name,
          phone,
          address,
          district,
          pin,
          state,
          gstno,
          batchno,
          unitcost,
          totalquantity,
          gst,
          grandtotal,
          doe,
          dom,
          upccode,
          productname,
          manufacturer,
          imID,
          backDate,
          vendorid,
        });

        // // Check if the vendor exists
        // let vendor = vendorid;
        // if (!vendor) {
        //   const vendorResponse = await Axios.post(
        //     `${process.env.REACT_APP_BASE_URL}postvendor`,
        //     {
        //       hospitalid,
        //       name,
        //       phone,
        //       address: address,
        //       district: district,
        //       pin: pin,
        //       state: state,
        //       gstno: gstno,
        //       status: "active",
        //     }
        //   );
        //   vendor = vendorResponse.data.vendorid;
        //   setVendorId(vendorResponse.data.vendorid);
        // }

        const fulldate = new Date().toLocaleDateString("en-IN");

        const history = {
          hospitalid: hospitalid,
          date: fulldate,
          productid: productid,
          quantity: totalquantity,
          type: "Stock Entry",
          remark: "valid",
          batch: batchno,
          imid: localStorage.getItem("inventorymanagerid"),
        };

        console.log(
          "Inventory Manager ID is " +
          localStorage.getItem("inventorymanagerid"),
        );

        // console.log("vendor id is " + vendor);
        const response = await Axios.post(
          `${process.env.REACT_APP_BASE_URL}poststocks`,
          {
            hospitalid,
            productid,
            name,
            phone,
            batchno,
            unitcost,
            totalquantity,
            gst,
            grandtotal,
            buffervalue: totalquantity * 0.15,
            doe: doe,
            dom: dom,
            upccode,
            productname,
            manufacturer,
            imid: localStorage.getItem("inventorymanagerid").toString(),
            backDate,
            vendorid, // Use the vendorid
          },
        );

        const historyresponse = await Axios.post(
          `${process.env.REACT_APP_BASE_URL}posthistory`,
          history,
        );
      }

      setIsStockRegistered(true);
      setOpen(true);
      setStockEntries([]);
    } catch (error) {
      setShowAlertDialog(true);
      setAlertText("Error Registering Stocks");
      console.error("Error creating Stocks:", error);
    }
  };

  const clearAllFields = () => {
    formik.resetForm();
    setSearchTerm("");
    setSelectedProducts(null);
    setCategory(null);
    setType(null);
    setUpc(null);
    setManufacturer(null);
    setGST(0);
    setGrandTotal(0);
    setId(null);
    setName("");
    setDoe(null);
    setDom(null);
    setProductImage(null);
  };

  useEffect(() => {
    const calculateGrandTotal = () => {
      const totalCost = formik.values.unitcost * formik.values.totalquantity;
      const gstAmount = (formik.values.gst / 100) * totalCost;
      const grandTotal = totalCost + gstAmount;
      setGrandTotal(grandTotal.toFixed(2));
    };

    calculateGrandTotal();
  }, [formik.values.unitcost, formik.values.totalquantity, formik.values.gst]);

  const formatDate = (date) => (date ? dayjs(date).format("DD/MM/YYYY") : "");

  const searchedStates =
    formik.values.state && !states.some(el => el.name === formik.values.state)
      ? states.filter(el => el.name.toLowerCase().includes(formik.values.state.toLowerCase()))
      : states;

  const [showStates, setShowStates] = useState(false)

  return (
    <form onSubmit={formik.handleSubmit}>
      <AlertDialog
        onClose={() => setShowAlertDialog(false)}
        open={showAlertDialog}
        text={alertText}
      />
      <LoaderOverlay loading={loading} />
      <div>
        {isStockRegistered && (
          <PopupMessage message="Stock Registered Successfully" />
        )}
        {errorMessage && <PopupMessage message={errorMessage} />}
        <section
          className="p-5 w-100"
          style={{ backgroundColor: "#eeeee", borderRadius: ".5rem .5rem 0 0", fontSize: "15px" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-3">
                  <div className="row">
                    <div className="col">
                      <p className="text-left h2 mb-3 mt-4">
                        Stock Information:
                      </p>

                      {/* Product Search and Details Section */}
                      <div className="row mt-3">
                        <InputLabel id="demo-simple-select-label">
                          UPC/Product Name*
                        </InputLabel>
                        <div style={{ position: "relative" }}>
                          <SearchContainer>
                            <SearchIconWrapper>
                              <SearchIcon
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "19px",
                                  transform: "translateY(-50%)",
                                }}
                              />
                            </SearchIconWrapper>
                            <input
                              placeholder={
                                selectedProducts
                                  ? selectedProducts.name
                                  : "Search Your Product"
                              }
                              aria-label="search"
                              value={formik.values.productname} // Bind Formik's value
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "productname",
                                  e.target.value,
                                ); // Update Formik's state
                                handleSearchChange(e); // Call custom search handler
                              }}
                              onBlur={formik.handleBlur} // Handle blur with Formik
                              style={{
                                width: "100%",
                                paddingLeft: "50px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            />
                          </SearchContainer>
                          {searchResults.length > 0 && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "white",
                                width: "100%",
                                zIndex: 1,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {searchResults.map((product) => (
                                <div
                                  key={product._id}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    handleProductSelect(product)
                                    formik.setFieldValue("productname", product.name)
                                  }}
                                >
                                  {highlightSearchTerm(product.name)}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {!selectedProducts && formik.touched.productname ? (
                          <div className="mt-1">
                            <small className="text-danger">
                              Please Select a Product
                            </small>
                          </div>
                        ) : null}
                      </div>
                      {/* Other input fields */}
                      <div className="row mt-3">
                        <label htmlFor="firstname" className="form-label">
                          Product UPC
                        </label>
                        <input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder={upc}
                          value={formik.values.upccode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>

                      <div className="row mt-3">
                        <label htmlFor="firstname" className="form-label">
                          Manufacturer
                        </label>
                        <input
                          id="manufacturer"
                          name="manufacturer"
                          className="form-control"
                          value={formik.values.manufacturer}
                          placeholder={manufacturer}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-3">
                        <label htmlFor="email" className="form-label">
                          Product Type
                        </label>
                        <input
                          id="email"
                          name="email"
                          className="form-control"
                          value={formik.values.email}
                          placeholder={type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-3">
                        <label htmlFor="category" className="form-label">
                          Product Category/Sub Category
                        </label>
                        <input
                          id="category"
                          name="category"
                          className="form-control"
                          placeholder={category}
                          value={formik.values.category}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="text"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col md-5 ">
                      <div className="image-upload-container">
                        <Box
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 500,
                            overflow: "hidden", // Ensures the image does not extend beyond the container bounds
                          }}
                        >
                          {productImage ? (
                            <img
                              src={productImage}
                              alt="Product"
                              style={{
                                width: "100%", // Ensures the image fills the container
                                height: "100%",
                                objectFit: "cover", // Ensures the image covers the container without distortion
                              }}
                            />
                          ) : (
                            <img
                              width="96"
                              height="96"
                              src="http://img.icons8.com/color/96/add-image.png"
                              alt="add"
                            />
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row">
                      <p className="text-left h2 mb-3 mt-4">Vendor Details</p>
                      <div className="row mt-3" style={{ alignItems: "end" }}>
                        <div className="col text-left">
                          <label htmlFor="name" className="form-label">
                            Name*
                          </label>
                          <input
                            id="name"
                            name="name"
                            className="form-control"
                            value={formik.values.name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleVendorSearchChange(e);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {vendorSearchResults.length > 0 && debouncedVendorSearchTerm !== "" &&
                            (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  width: getInputWidth("name"),
                                  zIndex: 1,
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {vendorSearchResults.map((vendor) => (
                                  <div
                                    key={vendor._id}
                                    style={{
                                      padding: "8px",
                                      cursor: "pointer",
                                      fontSize: "16px",
                                    }}
                                    onClick={() => handleVendorSelect(vendor)}
                                  >
                                    {vendor.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          {formik.errors.name && formik.touched.name ? (
                            <small className="text-danger mt-1">
                              {formik.errors.name}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="phone-vendor" className="form-label">
                            Ph. Number*
                          </label>
                          <input
                            id="phone-vendor"
                            name="phone"
                            className="form-control"
                            value={formik.values.phone}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleVendorSearchChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            type="text"
                            disabled={formik.values.vendorid !== ""}
                          />
                          {vendorSearchResults.length > 0 && vendorSearchResults[0].field === 'phone' && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "white",
                                width: getInputWidth("phone"),
                                zIndex: 1,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {vendorSearchResults.map((vendor) => (
                                <div
                                  key={vendor._id}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => handleVendorSelect(vendor)}
                                >
                                  {vendor.phone}
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.phone && formik.touched.phone ? (
                            <small className="text-danger mt-1">
                              {formik.errors.phone}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="address-vendor" className="form-label">
                            Address*
                          </label>
                          <input
                            id="address-vendor"
                            name="address"
                            className="form-control"
                            value={formik.values.address}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleVendorSearchChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.vendorid !== ""}
                          />
                          {formik.errors.address && formik.touched.address ? (
                            <small className="text-danger mt-1">{formik.errors.address}</small>
                          ) : null}
                        </div>

                        <div className="col text-left">
                          <label htmlFor="district" className="form-label">
                            District*
                          </label>
                          <input
                            id="district"
                            name="district"
                            className="form-control"
                            value={formik.values.district}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleVendorSearchChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.vendorid !== ""}
                          />
                          {formik.errors.district && formik.touched.district ? (
                            <small className="text-danger mt-1">{formik.errors.district}</small>
                          ) : null}
                        </div>

                        <div className="col text-left">
                          <label htmlFor="pin" className="form-label">
                            Pin*
                          </label>
                          <input
                            id="pin"
                            name="pin"
                            className="form-control"
                            value={formik.values.pin}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleVendorSearchChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.vendorid !== ""}
                          />
                          {formik.errors.pin && formik.touched.pin ? (
                            <small className="text-danger mt-1">{formik.errors.pin}</small>
                          ) : null}
                        </div>

                        <div className="col text-left">
                          <label htmlFor="state" className="form-label">
                            State*
                          </label>
                          <input
                            id="state"
                            name="state"
                            className="form-control"
                            value={formik.values.state || ""}
                            onChange={(e) => {
                              formik.setFieldValue("state", e.target.value);
                            }}
                            type="text"
                            disabled={formik.values.vendorid !== ""}
                            onFocus={() => setShowStates(true)}
                          />
                          {states.length > 0 && showStates && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "white",
                                width: getInputWidth("state"),
                                zIndex: 1,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {searchedStates.map((state) => (
                                <div
                                  key={state._id}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                  onClick={() => {
                                    formik.setFieldValue("state", state.name);
                                    setShowStates(false);
                                  }}
                                >
                                  {state.name}
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.state && formik.touched.state ? (
                            <small className="text-danger mt-1">{formik.errors.state}</small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="gstno" className="form-label">
                            GST Number*
                          </label>
                          <input
                            id="gstno"
                            name="gstno"
                            className="form-control"
                            value={formik.values.gstno}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 15) {
                                formik.setFieldValue("gstno", value); // Update Formik's state
                                handleVendorSearchChange(e); // Trigger vendor search logic
                              }
                            }}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.vendorid !== ""} // Disable if vendor is selected
                          />
                          {/* Vendor search results dropdown */}
                          {vendorSearchResults.length > 0 &&
                            vendorSearchResults[0].field === "gstno" && (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  width: getInputWidth("gstno"),
                                  zIndex: 1,
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {vendorSearchResults.map((vendor) => (
                                  <div
                                    key={vendor._id}
                                    style={{
                                      padding: "8px",
                                      cursor: "pointer",
                                      fontSize: "16px",
                                    }}
                                    onClick={() => handleVendorSelect(vendor)}
                                  >
                                    {vendor.gstno}
                                  </div>
                                ))}
                              </div>
                            )}
                          {/* Validation error message */}
                          {formik.errors.gstno && formik.touched.gstno ? (
                            <small className="text-danger mt-1">{formik.errors.gstno}</small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#2e718a", ":hover": { backgroundColor: "#c45516" } }}
                            size="medium"
                            onClick={() => {
                              formik.setFieldValue("name", "");
                              formik.setFieldValue("phone", "");
                              formik.setFieldValue("address", "");
                              formik.setFieldValue("district", "");
                              formik.setFieldValue("pin", "");
                              formik.setFieldValue("state", "");
                              formik.setFieldValue("gstno", "");
                              formik.setFieldValue("vendorid", "");
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>

                      <p className="text-left h2 mb-3 mt-4">Stock Details</p>
                      <div className="row mt-3">
                        <div className="col text-left">
                          <label htmlFor="batchno" className="form-label">
                            Batch Number*
                          </label>
                          <input
                            id="batchno"
                            name="batchno"
                            className="form-control"
                            value={formik.values.batchno}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.batchno && formik.touched.batchno ? (
                            <small className="text-danger mt-1">
                              {formik.errors.batchno}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="unitcost" className="form-label">
                            Unit Cost* (₹)
                          </label>
                          <input
                            id="unitcost"
                            name="unitcost"
                            className="form-control"
                            value={
                              formik.values.unitcost
                                ? `₹ ${Number(formik.values.unitcost).toLocaleString("en-IN")}`
                                : ""
                            }
                            onChange={(e) => {
                              const value = e.target.value.replace(/,/g, "").replace(/₹/g, ""); // Remove commas and ₹ before updating
                              formik.setFieldValue("unitcost", value);
                            }}
                            onBlur={formik.handleBlur}
                            type="text"
                          />
                          {formik.errors.unitcost && formik.touched.unitcost ? (
                            <small className="text-danger mt-1">{formik.errors.unitcost}</small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="totalquantity" className="form-label">
                            Total Quantity*
                          </label>
                          <input
                            id="totalquantity"
                            name="totalquantity"
                            className="form-control"
                            value={
                              formik.values.totalquantity
                                ? Number(formik.values.totalquantity).toLocaleString("en-IN")
                                : ""
                            }
                            onChange={(e) => {
                              const value = e.target.value.replace(/,/g, ""); // Remove commas before updating
                              formik.setFieldValue("totalquantity", value);
                            }}
                            onBlur={formik.handleBlur}
                            type="text"
                          />
                          {formik.errors.totalquantity && formik.touched.totalquantity ? (
                            <small className="text-danger mt-1">{formik.errors.totalquantity}</small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="gst" className="form-label">
                            GST Rate*
                          </label>
                          <select
                            id="gst"
                            name="gst"
                            className="form-control"
                            value={formik.values.gst} // Bind Formik's value
                            onChange={formik.handleChange} // Handle change with Formik
                            onBlur={formik.handleBlur} // Handle blur with Formik
                          >
                            <option value="">Select GST Rate</option>
                            <option value="5">5%</option>
                            <option value="10">10%</option>
                            <option value="12">12%</option>
                            <option value="18">18%</option>
                            <option value="20">20%</option>

                            <option value="28">28%</option>
                          </select>
                          {formik.touched.gst && formik.errors.gst ? (
                            <small className="text-danger mt-1">
                              {formik.errors.gst}{" "}
                              {/* Show Formik's error message */}
                            </small>
                          ) : null}
                        </div>

                        <div className="col text-left">
                          <label htmlFor="grandtotal" className="form-label">
                            Grand Total (₹)
                          </label>
                          <input
                            id="grandtotal"
                            name="grandtotal"
                            className="form-control"
                            value={`₹ ${Number(grandtotal).toLocaleString("en-IN")}`}
                            type="text"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col text-center">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Back date"
                              format="DD/MM/YYYY"
                              value={formik.values.backDate ? dayjs(
                                formik.values.backDate,
                                "DD-MM-YYYY",
                              ) : null}
                              onChange={(newValue) => {
                                formik.setFieldValue(
                                  "backDate",
                                  newValue ? newValue.format("DD-MM-YYYY") : "",
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="col text-center">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date of Manufacturing*"
                              value={
                                formik.values.dom
                                  ? dayjs(formik.values.dom, "DD/MM/YYYY")
                                  : null
                              }
                              onChange={(newValue) => {
                                if (newValue && newValue.isAfter(dayjs())) {
                                  alert(
                                    "Invalid Date. Please select a date before the current date.",
                                  );
                                  formik.setFieldValue("dom", ""); // Reset if invalid
                                } else {
                                  formik.setFieldValue(
                                    "dom",
                                    newValue
                                      ? newValue.format("DD/MM/YYYY")
                                      : "",
                                  );
                                  formik.setFieldValue("doe", ""); // Reset DOE when DOM is selected
                                }
                              }}
                              onBlur={() => formik.setFieldTouched("dom", true)}
                              maxDate={dayjs()} // Disable dates after the current date
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                          {formik.touched.dom && formik.errors.dom ? (
                            <div className="mt-1">
                              <small className="text-danger">
                                {formik.errors.dom}
                              </small>
                            </div>
                          ) : null}
                        </div>
                        <div className="col text-center">
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={
        type === "Equipments"
          ? "Date of AMC"
          : type === "Pharmaceuticals" || type === "Consumables"
          ? "Date of Expiry"
          : "Date of Expiry"
      }
      value={
        formik.values.doe
          ? dayjs(formik.values.doe, "DD/MM/YYYY")
          : null
      }
      onChange={(newValue) => {
        if (
          formik.values.dom &&
          newValue &&
          newValue.isBefore(
            dayjs(formik.values.dom, "DD/MM/YYYY")
          )
        ) {
          alert(
            "Invalid Date. Please select a date after the Date of Manufacturing."
          );
          formik.setFieldValue("doe", formik.values.dom); // Set to DOM if invalid
        } else {
          formik.setFieldValue(
            "doe",
            newValue ? newValue.format("DD/MM/YYYY") : ""
          );
        }
      }}
      onBlur={() => formik.setFieldTouched("doe", true)}
      minDate={
        formik.values.dom
          ? dayjs(formik.values.dom, "DD/MM/YYYY")
          : undefined
      } // Restrict to dates after DOM
      disabled={!formik.values.dom} // Disable DOE field when DOM is not selected
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
  {formik.touched.doe && formik.errors.doe ? (
    <div className="mt-1">
      <small className="text-danger">{formik.errors.doe}</small>
    </div>
  ) : null}
</div>
                      </div>

                      <div className="row mt-3 button-row">
                        <div className="d-flex justify-content-end">
                          <div className=" actionButtons">
                            <Button
                              variant="contained"
                              onClick={clearAllFields}
                              size="large"
                              className="mr-3"
                            >
                              Clear
                            </Button>
                          </div>
                          <div className="button-spacing"></div>{" "}
                          <div className="actionButtons">
                            {/* Add this div for spacing */}
                            <Button
                              variant="contained"
                              size="large"
                              onClick={() => {
                                console.log("Button Clicked");
                                formik.handleSubmit();
                              }}
                            >
                              Add Stock
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col text-center">
                      <h3>Products List</h3>
                    </div>
                  </div>
                  <div style={{ overflowX: "auto" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>UPC</th>
                          <th>Name</th>
                          <th>Mfg.</th>
                          <th>Vendor</th>
                          <th>Batch No.</th>
                          <th>Unit Cost</th>
                          <th>Qty.</th>
                          <th>Date of Mfg.</th>
                          <th>Date of Exp./PM.</th>
                          <th>GST Rate</th>
                          <th>Grand Total</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stockEntries.map((stockEntry, index) => (
                          <tr key={index}>
                            <td>{stockEntry.upccode}</td>
                            <td>{stockEntry.productname}</td>
                            <td>{stockEntry.manufacturer}</td>
                            <td>{stockEntry.name}</td>
                            <td>{stockEntry.batchno}</td>
                            <td>{stockEntry.unitcost}</td>
                            <td>{stockEntry.totalquantity}</td>
                            <td>{stockEntry.dom}</td>
                            <td>{stockEntry.doe}</td>
                            {/* <td>
                                {stockEntry.dom instanceof Date
                                  ? stockEntry.dom.toLocaleDateString()
                                  : ""}
                              </td>
                              <td>
                                {stockEntry.doe instanceof Date
                                  ? stockEntry.doe.toLocaleDateString()
                                  : ""}
                              </td> */}
                            <td>{stockEntry.gst}</td>
                            <td>{stockEntry.grandtotal}</td>
                            <td>
                              <IconButton
                                style={{
                                  backgroundColor: "white",
                                  color: "green",
                                  transition:
                                    "background-color 0.3s, color 0.3s",
                                }}

                              //  onClick={() => editProduct(index)}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                style={{
                                  backgroundColor: "white",
                                  color: "red",
                                  transition:
                                    "background-color 0.3s, color 0.3s",
                                }}

                              // onClick={() => removeProduct(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="col text-center actionButtons">
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSubmitAllStockEntries}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Missing Information</DialogTitle>
          <DialogContent>
            <p>The following fields are missing:</p>
            <ul>
              {missingFields.map((field) => (
                <li key={field}>{field}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    </form >
  );
};

export default StockEntry;

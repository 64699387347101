import { useState } from "react";
import { TextField, FormControlLabel, Switch, Box, Container, Button, Typography } from "@mui/material";
import axios from "axios";

const VendorForm = ({ vendorData, handleFormSubmit, isAdmin = false, showDoc = true }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState(vendorData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [docName, setDocName] = useState(vendorData.document_name ? vendorData.document_name : "")

  const handleSwitchChange = () => {
    setIsEditable((prev) => !prev);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit(formData)
    setIsEditable(false)
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;
    if (file.type !== "application/pdf") {
      alert("Please select a valid PDF file.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + "v2/vendor/upload-pdf/" + vendorData._id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setDocName(response.data.fileName)
    } catch (error) {
      console.error("File upload error:", error);
      alert("File upload failed.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        {!isAdmin && (
          <FormControlLabel
            control={<Switch checked={isEditable} onChange={handleSwitchChange} />}
            label="Enable Editing"
          />
        )}
        <form onSubmit={onFormSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="District"
            name="district"
            value={formData.district}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="PIN"
            name="pin"
            value={formData.pin}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          <TextField
            label="GST Number"
            name="gstno"
            value={formData.gstno}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable}
          />
          {!isAdmin && (
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={!isEditable}>
              Submit
            </Button>
          )}
        </form>

        {showDoc && (
          <Box sx={{ mt: 4 }}>
            {vendorData.document_name && vendorData.document_name !== "" ? (
              <>
                <iframe
                  src={`${process.env.REACT_APP_BASE_URL}vendor-docs/${docName}`}
                  width="100%"
                  height="500px"
                  title="Vendor Document"
                ></iframe>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1">Upload new PDF Document:</Typography>
                  <input type="file" accept="application/pdf" onChange={handleFileChange} />
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body1">Upload PDF Document:</Typography>
                <input type="file" accept="application/pdf" onChange={handleFileChange} />
                {selectedFile && <Typography variant="body2" sx={{ mt: 1 }}>Selected File: {selectedFile.name}</Typography>}
              </>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default VendorForm;

import { useEffect, useState } from "react";
import AmcTable from "../../components/AmcScreen/AmcTable";
import MainScreenContainer from "../../components/UI/MainScreenContainer";

const hospitalId = localStorage.getItem("hospitalid");
export default function AmcScreen() {
  const [loading, setLoading] = useState(false);
  const [expiringProducts, setExpiringProducts] = useState([]);

  useEffect(() => {
    async function getExpiryProducts() {
      const url = `${process.env.REACT_APP_BASE_URL}get-expiring-products/${hospitalId}`;
      setLoading(true);
      try {
        const res = await fetch(url);
        if (!res.ok) {
          throw new Error("something went wrong");
        }
        const data = await res.json();
        setExpiringProducts(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    getExpiryProducts();
  }, []);
  return (
    <MainScreenContainer heading={"AMC Products"} loading={loading}>
      <AmcTable products={expiringProducts} />
    </MainScreenContainer>
  );
}

import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import VendorForm from "../VendorView/VendorForm"
import { Alert, Box, Button, Snackbar, Stack } from "@mui/material"
import LoaderOverlay from "../Loader/LoaderOverlay"

export default function VendorCompare() {
  const navigate = useNavigate()

  const { requestId } = useParams()
  const [reqData, setReqData] = useState(null)
  const [reqStatus, setReqSatus] = useState("loading")
  const [updateStatus, setUpdateSatus] = useState("idle")

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarErr, setSnackbarErr] = useState("")
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    async function getRequest(reqId) {
      if (!reqId) return
      try {
        const url = process.env.REACT_APP_BASE_URL + "v2/vendor/update-request/" + reqId
        const { data } = await axios.get(url)
        setReqData(data)
        setReqSatus("success")
      } catch (error) {
        console.log(error)
        setReqSatus("error")
      }
    }

    getRequest(requestId)
  }, [requestId])

  async function updateVednor() {
    setUpdateSatus("loading")
    try {
      const url = process.env.REACT_APP_BASE_URL + "v2/vendor/" + reqData.vendor_id._id
      await axios.put(url, { ...reqData.vendor_demand, requestId })
      navigate("/requeststatus")
    } catch (error) {
      setSnackbarErr("Something went wrong!")
      setOpenSnackbar(true)
    } finally {
      setUpdateSatus("idle")
    }
  }

  async function rejectRequest() {
    setUpdateSatus("loading")
    try {
      const url = process.env.REACT_APP_BASE_URL + "v2/vendor/reject-request/" + requestId
      await axios.put(url)
      navigate("/requeststatus")
    } catch (error) {
      setSnackbarErr("Something went wrong!")
      setOpenSnackbar(true)
    } finally {
      setUpdateSatus("idle")
    }
  }


  return (
    <Box>
      <LoaderOverlay loading={reqStatus === "laoding"} />
      {reqStatus === "success" && (
        reqData ? (
          <>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", placeItems: "center", paddingTop: "30px" }}>
              <div>
                <h3 style={{ textAlign: "center" }}>Original</h3>
                <VendorForm isAdmin={true} handleFormSubmit={() => { }} vendorData={reqData.vendor_id} showDoc={false} />
              </div>
              <div>
                <h3 style={{ textAlign: "center" }}>Edited</h3>
                <VendorForm isAdmin={true} handleFormSubmit={() => { }} vendorData={reqData.vendor_demand} showDoc={false} />
              </div>
            </div>
            <Stack direction="row" alignItems="center" justifyContent="end" paddingRight={10} gap={4}>
              <Button variant="contained" color="success" size="large" disabled={updateStatus === "loading"} onClick={updateVednor}>Update</Button>
              <Button variant="contained" color="error" size="large" disabled={updateStatus === "loading"} onClick={rejectRequest}>Reject</Button>
            </Stack>
          </>
        ) : (
          <article style={{ minHeight: "90vh", display: "grid", placeItems: "center" }}>
            <p>Something went wrong!!!</p>
          </article>
        )
      )
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarErr}
        </Alert>
      </Snackbar>
    </Box >
  )
}

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import LoaderOverlay from "../Loader/LoaderOverlay.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./UserRegistration.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";


function createData(
  requestdate,
  requestid,
  productid,
  imname,
  productname,
  requesttype,
  status,
  navigate,
  vendorName,
  vendorDemand
) {
  let statusButton;
  let requestTypeButton;
  const id = productid;

  if (status === "pending") {
    statusButton = (
      <Button variant="warning" size="sm">
        Pending
      </Button>
    );
  } else if (status === "accepted") {
    statusButton = (
      <Button variant="success" size="sm">
        Accepted
      </Button>
    );
  } else if (status === "rejected") {
    statusButton = (
      <Button variant="danger" size="sm">
        Rejected
      </Button>
    );
  }
  if (status == "pending") {
    if (requesttype === "delete") {
      requestTypeButton = (
        <Button
          variant="contained"
          size="small"
          style={{ color: "red" }}
          onClick={() =>
            navigate(`/productdetails`, { state: { id, requestid } })
          }
        >
          <DeleteOutlined />
        </Button>
      );
    } else if (!requesttype && vendorDemand) {
      requestTypeButton = (
        <Button
          variant="contained"
          size="small"
          style={{ color: "#2E718A" }}
          onClick={() => {
            const url = "/vendor-compare/" + requestid
            navigate(url)
          }}
        >
          <EditIcon />
        </Button >
      );
    } else if (requesttype != "delete") {
      requestTypeButton = (
        <Button
          variant="contained"
          size="small"
          style={{ color: "#2E718A" }}
          onClick={() =>
            navigate(`/productcompare`, {
              state: { id, requestid, requesttype },
            })
          }
        >
          <EditIcon />
        </Button>
      );
    }
  } else {
    if (requesttype === "delete") {
      requestTypeButton = (
        <Button variant="contained" size="small" style={{ color: "red" }}>
          <DeleteOutlined />
        </Button>
      );
    } else if (requesttype != "delete") {
      requestTypeButton = (
        <Button variant="contained" size="small" style={{ color: "#2E718A" }}>
          <EditIcon />
        </Button>
      );
    }
  }

  return { requestdate, imname, productname, requestTypeButton, statusButton, vendorName };
}

function RequestStatus({ openSidebarToggle, OpenSidebar }) {
  const navigate = useNavigate();

  const hospitalid = localStorage.getItem("hospitalid");
  let [loading, setLoading] = useState(false);


  const [rows, setRows] = useState([])

  const getrequests = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}requestbyhospitalid/${hospitalid}`;
      const { data } = await axios.get(url);

      const a = data.document.map(item => {
        return createData(
          item.requestdate,
          item._id,
          item.productid ? item.productid : item.vendor_id,
          item.IMDetails.name,
          item.productDetails?.name || "N/A",
          item.demand,
          item.status,
          navigate,
          item.vendor_id?.name || "N/A",
          item.vendor_demand
        )
      });
      console.log(a)
      setRows(a)

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getrequests();
  }, []);


  return (
    <div>
      <LoaderOverlay loading={loading} />
      <section className="p-5 w-100">
        <div className="row">
          <div className="col-12">
            <div className="card-body p-md-50">
              <div className="row justify-content-center">
                <div>
                  <div className="button-body mt-2 mb-2">
                    <div className="d-flex justify-content-center">
                      {/* <Button
                        variant="primary"
                        size="lg"
                        onClick={toggleModalOpenState}
                        style={{ backgroundColor: "#1C647C" }}
                      >
                        Add User
                      </Button> */}
                    </div>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Request Date
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            IM Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Vendor Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Request Type
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.length > 0 && rows.map((row, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {row.requestdate}
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {row.imname}
                            </TableCell>
                            <TableCell align="center">
                              {row.productname}
                            </TableCell>
                            <TableCell align="center">
                              {row.vendorName}
                            </TableCell>
                            <TableCell align="center">
                              {row.requestTypeButton}
                            </TableCell>
                            <TableCell align="center">
                              {row.statusButton}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={toggleModalOpenState}
                    className="source-type-modal"
                    aria-labelledby="source-type-dialog-label"
                    onAfterOpen={() => {
                      setTimeout(() => firstInputRef.current?.focus(), 0);
                    }}
                  >
                    <Box sx={{ ...style, width: 700 }}>
                    <div className="d-flex justify-content-end">
      <CloseButton
        onClick={toggleModalOpenState}
        style={{ position: "absolute", top: "10px", center: "10px" }}
      />
    </div>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Add User
                      </Typography>
                      <FormControl fullWidth>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="role-label"
                          id="role-select"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <MenuItem value="admin">Inventory Manager</MenuItem>
                          
                        </Select>
                      </FormControl>
                      <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <div className="d-flex justify-content-center">
                        <Button
                          value="apply"
                          variant="primary"
                        size="lg"
                        style={{ backgroundColor: "#1C647C" }}
                         // className="source-type-modal__control-btn source-type-modal__control-btn--apply"
                          onClick={() => {
                            console.log("applying source types");
                            toggleModalOpenState();
                            handleSubmit();
                          }}
                        >
                          Add
                        </Button>
                       
                      </div>
                    </Box>
                  </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RequestStatus;

import { Alert, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainScreenContainer from "../UI/MainScreenContainer";
import VendorForm from "./VendorForm";

export default function VendorProfile({ isAdmin = false }) {
  const { vendorId } = useParams()
  const [vendor, setVendor] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarErr, setSnackbarErr] = useState("")

  useEffect(() => {
    async function getVendorDetails(id) {
      if (!vendorId) return
      setLoading(true)
      try {
        const url = process.env.REACT_APP_BASE_URL + "v2/vendor/" + id;
        const { data } = await axios.get(url)
        setVendor(data)
      } catch (err) {
        setError(err.response.data.error)
      } finally {
        setLoading(false)
      }
    }

    getVendorDetails(vendorId)
  }, [vendorId])

  async function mutateVendor(formData) {
    const request = {
      userid: localStorage.getItem("id"),
      hospitalid: localStorage.getItem("hospitalid"),
      inventorymanagerid: localStorage.getItem("inventorymanagerid"),
      vendor_id: formData._id,
      demand: formData,
      status: "pending",
      requestdate: new Date().toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    };
    setLoading(true)
    try {
      const url = process.env.REACT_APP_BASE_URL + "v2/vendor/update-request"
      const response = await axios.post(
        url,
        request
      );
      // setVendor(response.data);
    } catch (err) {
      setOpenSnackbar(true)
      setSnackbarErr(err.response.data.error);
    } finally {
      setLoading(false)
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <MainScreenContainer loading={loading} heading="Vendor Profile">
      {error || !vendor ?
        (<Typography align="center">{error}</Typography>) : (
          <VendorForm vendorData={vendor} handleFormSubmit={mutateVendor} isAdmin={isAdmin} />
        )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarErr}
        </Alert>
      </Snackbar>
    </MainScreenContainer>
  )
}

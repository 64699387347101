import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import DataTable, { TableFilterBtn } from "../UI/DataTable";

export default function AmcTable({ products }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch product types, categories, and subcategories dynamically
  async function fetchDetails() {
    try {
      console.log("Fetching product types, categories, and subcategories...");

      // Fetch product types
      const productTypesResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}product-types`
      );
      const productTypes = productTypesResponse.data.productTypes || [];

      // Fetch categories for each product type
      const categories = {};
      for (const productType of productTypes) {
        const categoriesResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}categories/${productType._id}`
        );
        categories[productType._id] = categoriesResponse.data.categories || [];
      }

      // Fetch subcategories for each category
      const subcategories = {};
      for (const productTypeId in categories) {
        for (const category of categories[productTypeId]) {
          const subcategoriesResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}subcategories/${category._id}`
          );
          subcategories[category._id] =
            subcategoriesResponse.data.subcategories || [];
        }
      }

      console.log("Fetched Product Types:", productTypes);
      console.log("Fetched Categories:", categories);
      console.log("Fetched Subcategories:", subcategories);

      return { productTypes, categories, subcategories };
    } catch (error) {
      console.error("Error fetching details:", error);
      return { productTypes: [], categories: {}, subcategories: {} };
    }
  }

  // Generate rows with resolved names and filter by producttype "Equipments"
  async function generateRows(products) {
    if (!products || products.length === 0) {
      console.log("No products provided.");
      return [];
    }

    try {
      setLoading(true);

      const { productTypes, categories, subcategories } = await fetchDetails();

      const rows = products
        .map((el) => {
          const productTypeName =
            productTypes.find((type) => type._id === el.productid?.producttype)
              ?.name || "n/a";

          // Only include rows where producttype is "Equipments"
          if (productTypeName !== "Equipments") {
            console.log(
              `Skipping product: ${el.productid?.name} (producttype: ${productTypeName})`
            );
            return null;
          }

          const categoryName =
            categories[el.productid?.producttype]?.find(
              (cat) => cat._id === el.productid?.category
            )?.name || "n/a";

          const subcategoryName =
            subcategories[el.productid?.category]?.find(
              (subcat) => subcat._id === el.productid?.subcategory
            )?.name || "n/a";

          console.log("Adding Product:", {
            productName: el.productid?.name,
            productTypeName,
            categoryName,
            subcategoryName,
          });

          return {
            proName: el.productid?.name || "n/a",
            category: categoryName,
            subCate: subcategoryName,
            type: productTypeName,
            date: el.doe,
            origin: el.productid?.origin || "n/a",
            manufac: el.productid?.manufacturer || "n/a",
            id: el._id,
            emer: el.productid?.emergencytype || "n/a",
          };
        })
        .filter((row) => row !== null); // Remove null rows

      setLoading(false);
      return rows;
    } catch (error) {
      console.error("Error generating rows:", error);
      setLoading(false);
      return [];
    }
  }

  useEffect(() => {
    async function fetchRows() {
      const generatedRows = await generateRows(products);
      setRows(generatedRows);
    }

    fetchRows();
  }, [products]);

  //******************************************************//
  // Define columns directly in this file
  const imId = localStorage.getItem("inventorymanagerid");

  const columns = [
    {
      field: "date",
      headerName: "DATE OF EXPIRATION",
      headerAlign: "left",
      width: 200,
      align: "left",
    },
    { field: "proName", headerName: "PRODUCT NAME", width: 200 },
    {
      field: "type",
      headerName: "PRODUCT TYPE",
      headerAlign: "left",
      width: 200,
      align: "left",
    },
    { field: "category", headerName: "CATEGORY", width: 200 },
    {
      field: "manufac",
      headerName: "MANUFACTURER",
      width: 200,
    },
    { field: "origin", headerName: "ORIGIN", width: 150 },
    {
      field: "subCate",
      headerName: "SUB CATEGORY",
      width: 200,
    },
    {
      field: "emer",
      headerName: "EMERGENCY TYPE",
      width: 200,
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 200,
      renderCell: (params) =>
        imId ? (
          <button
            style={{
              backgroundColor: "#2E718A",
              color: "white",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={() => handleRequestUpdate(params.row.id)}
          >
            Request Update
          </button>
        ) : (
          <button
            style={{
              backgroundColor: "#ccc",
              color: "#666",
              border: "none",
              padding: "5px 10px",
              cursor: "not-allowed",
            }}
            disabled
          >
            Pending
          </button>
        ),
    },
  ];

  // Function to handle the "Request Update" button click
  function handleRequestUpdate(rowId) {
    console.log(`Request update for row ID: ${rowId}`);
    // Add your logic here to handle the request update action
  }

  //******************************************************//
  // Export button state and handlers
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedDataObj =
    rows.length > 0
      ? rows.filter((el) => selectedRows.includes(el.id.toString()))
      : [];

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <ExportBtn
          rows={selectedDataObj}
          isSelected={true}
          headers={columns.map((col) => col.headerName)}
          fileName="Stockout_Report"
        />
      </Stack>
      <Box sx={{ height: 700, width: "100%", marginTop: "20px" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          loading={loading}
          disableColumnMenu
          disableColumnSorting
          checkboxSelection={true}
          onRowSelectionModelChange={(e) => {
            setSelectedRows(e);
          }}
          rowSelectionModel={selectedRows}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              marginTop: 0,
              marginBottom: 0,
            },
            "& .MuiTablePagination-selectLabel": {
              marginTop: 0,
              marginBottom: 0,
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              color: "#2E718A",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cellContent": {
              color: "black",
            },
          }}
        />
      </Box>
    </>
  );
}
import { Button } from "@mui/material";
import Person2Icon from '@mui/icons-material/Person2';
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";

const width = 160;
const disableColumnMenu = true;

export const vendorListColDef = [
  {
    field: "name",
    headerName: "Vendor Name",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "phone",
    headerName: "Phone",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "address",
    headerName: "Address",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "district",
    headerName: "District",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "state",
    headerName: "State",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "gstno",
    headerName: "GST Number",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 320, // Adjusted width to fit both buttons
    align: "left",
    renderCell: (params) => (
      <Stack direction="row" alignItems="center" spacing={2}>
        {/* View Stocks Button */}
        <Button
          variant="outlined"
          sx={{ color: "#2e718a", fontSize: "10px", ":hover": { color: "#c45516" } }}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            params.row.handleShowStock(params.row._id); // Trigger the stock view function
          }}
        >
          Avalaible Stocks
        </Button>

        {/* View Products Button */}
        <Button
          sx={{ fontSize: "10px" }}
          variant="outlined"
          color="success"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            params.row.handleShowProduct(params.row._id); // Trigger the product view function
          }}
        >
          View Products
        </Button>
        <Link to={params.row._id}>
          <Person2Icon sx={{ color: "#c45516" }} />
        </Link>
      </Stack>
    ),
  },
];

export const vendorListColDefAdmin = [
  {
    field: "name",
    headerName: "Vendor Name",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "phone",
    headerName: "Phone",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "address",
    headerName: "Address",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "district",
    headerName: "District",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "state",
    headerName: "State",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "gstno",
    headerName: "GST Number",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 320, // Adjusted width to fit both buttons
    align: "left",
    renderCell: (params) => (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          sx={{ fontSize: "10px" }}
          variant="outlined"
          color="success"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            params.row.handleViewHospital(params.row._id);
          }}
        >
          View Hospitals
        </Button>
        <Link to={params.row._id}>
          <Person2Icon sx={{ color: "#c45516" }} />
        </Link>
      </Stack>
    ),
  },
];

